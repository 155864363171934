import * as qf from '../misc/qf.js';
import React from 'react';

class IntroVideoPreview extends React.PureComponent {
  state = {
    opened: false,
  };

  _open = (event) => {
    event.preventDefault();
    if (!this.props.video.ready_at) {
      window.location.reload();
      return;
    }
    this.setState({
      opened: true,
    });
    this._onPlay();
  };

  _onPlay = () => {
    if (this.props.video._links.viewed) {
      qf.postJSON(this.props.video._links.viewed.href, {});
    }
  };

  render() {
    const video = this.props.video;
    if (!video) {
      return null;
    }
    if (this.state.opened) {
      if (video._links.embed) {
        return (
          <div className="AspectRatioBox AspectRatioBox--ratio-16-9">
            <iframe src={video._links.embed.href} frameBorder="0" allowFullScreen></iframe>
          </div>
        );
      } else if (video._embedded.video_link) {
        return (
          <div className="AspectRatioBox AspectRatioBox--ratio-16-9">
            <iframe src={video._embedded.video_link._links.embed.href} frameBorder="0" allowFullScreen></iframe>
          </div>
        );
      }
      const videoStyle = {
        height: '100%',
        width: '100%',
      };
      return (
        <div className="AspectRatioBox AspectRatioBox--ratio-16-9">
          <video controls controlsList="nodownload" preload="none" autoPlay src={video._links.direct_url.href} style={videoStyle} poster={video._links.thumbnail.href} onPlay={this._onPlay}>
            <source src={video._links.direct_url.href} type="video/mp4" />
          </video>
        </div>
      );
    }
    if (video._embedded.video_link) {
      const videoLink = video._embedded.video_link;
      return (
        <a className="SidebarIntroVideo" onClick={this._open} title={videoLink.description}>
          <div className="SidebarIntroVideo-thumbnail">
            <div className="AspectRatioBoxViewport">
              <img className="AspectRatioBoxViewport-imgCentered" src={videoLink._links.widescreen_thumbnail.href} />
              <span className="fa fa-play-circle-o SidebarHighlight-overlay"></span>
            </div>
          </div>
          <div className="SidebarIntroVideo-details">
            <div className="SidebarVideoVitalDetailsOverlayWrapper">
              <div className="SidebarVideoVitalDetailsOverlayInner">
                <div className="SidebarIntroVideo-title">{videoLink.description}</div>
              </div>
            </div>
            {
              videoLink._links.creator ? (
                <div className="SidebarHighlight-creator">
                  {videoLink._links.creator.title}
                </div>
              ): null
            }
          </div>
        </a>
      );
    }
    if (video.failed) {
      return null;
    }
    if (!video.ready_at) {
      return (
        <a className="SidebarIntroVideo" onClick={this._open} title={video.description}>
          <div className="SidebarIntroVideo-thumbnail">
            <div className="AspectRatioBoxPlaceholder AspectRatioBoxPlaceholder--noBorder" style={{backgroundColor: '#777', color: '#ddd', paddingLeft: 5, paddingRight: 5}}>
              <div className="AspectRatioBoxPlaceholder-text">
                <div className="fa fa-clock-o" style={{display: 'block', fontSize: '200%'}}></div>
                <span style={{fontSize: '85%'}}>The video is being processed.</span>
              </div>
            </div>
          </div>
          <div className="SidebarIntroVideo-details">
            <div className="SidebarVideoVitalDetailsOverlayWrapper">
              <div className="SidebarVideoVitalDetailsOverlayInner">
                <div className="SidebarIntroVideo-title">{video.description}</div>
              </div>
            </div>
            {
              video._links.creator ? (
                <div className="SidebarHighlight-creator">
                  {video._links.creator.title}
                </div>
              ): null
            }
          </div>
        </a>
      );
    }
    return (
      <a className="SidebarIntroVideo" onClick={this._open} title={video.description}>
        <div className="SidebarIntroVideo-thumbnail">
          <div className="AspectRatioBoxViewport">
            <img className="AspectRatioBoxViewport-imgCentered" src={video._links.thumbnail.href} />
            <span className="fa fa-play-circle-o SidebarHighlight-overlay"></span>
          </div>
        </div>
        <div className="SidebarIntroVideo-details">
          <div className="SidebarVideoVitalDetailsOverlayWrapper">
            <div className="SidebarVideoVitalDetailsOverlayInner">
              <div className="SidebarIntroVideo-title">{video.description}</div>
            </div>
          </div>
          {
            video._links.creator ? (
              <div className="SidebarHighlight-creator">
                {video._links.creator.title}
              </div>
            ): null
          }
        </div>
      </a>
    );
  }
}

export default IntroVideoPreview;
