import ReactDOM from 'react-dom';
import preloaded from '../src/preloaded.js';
import IntroVideoPreview from '../src/introVideos/IntroVideoPreview.js';

const target = document.getElementById('IntroVideoPreview');
if (target) {
  ReactDOM.render(
    <IntroVideoPreview video={preloaded.intro_video_preview} />
  , target);
}
